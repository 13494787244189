import { Component, OnInit } from '@angular/core';
import { servicesService } from '../../../../authentication/pre-signin/courses/services.service';
import { Router } from '@angular/router';
import { favouritesService } from 'src/app/core/services/favourites.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'vc-course-compare',
  templateUrl: './course-compare.component.html'
})
export class CourseCompareComponent implements OnInit {
  compareList: any[] = [];
  collectionName: string = 'courses';
  favouriteCoursesList: any;

  constructor(
    private servicesService: servicesService,
    private router: Router,
    private readonly favouritesService: favouritesService,
    private readonly snackbar: SnackBarService,
    public readonly authService: AuthenticationService,
    private readonly title:Title,
    private datePipe: DatePipe,
  ) { 
    this.servicesService.chatEvent$.subscribe(data => {
      if(data){
        this.loadCompareList();
      }
    });
  }

  ngOnInit() {
    this.title.setTitle("Course compare");
    this.loadCompareList();
  }
  loadCompareList(): void {
    const compareIds = JSON.parse(localStorage.getItem('compareCourses') || '[]');
    this.compareList = [];

    compareIds.forEach((courseId: string) => {
      this.servicesService.getCompareCoursesView(courseId).subscribe((result: any) => {

        this.compareList.push({
          id: courseId,
          name: result.course.courseName,  // Course title in the header
          cost: `£${this.getCurrentCourseCostDetails(result.courseCostDetails)[0]?.courseCost.toFixed(2)}`,  // Course cost formatted with £ and decimal points
          fundingType: result.course?.ref_course_funding_type?.name || 'Unknown',  // Funding Type
          courseDuration: result.numberOfDays,  // Next available course duration (days)
          seatsAvailable: result.availabilityCount > 0
            ? `${result.numberOfDays} days`
            : 'Fully booked',

          nextStartDate: result.availabilityCount > 0
            ? this.datePipe.transform(result?.deliveryStartDate, 'dd/MM/yyyy')
            : 'Fully booked',  // Show date only if seats are available

          location: result.availabilityCount > 0
            ? result.locationName
            : 'Fully booked',  // Show location only if seats are available
          qualifications: result.course.ref_course_quals || [],  // Qualifications
        });
      });
    });
    if (this.authService?.isLoggedIn) {
      this.getFavouritesByCollectionName(this.compareList);
    }
    
  }

  getCurrentCourseCostDetails(courseCostDetails: any[]) {
    let compareDate = new Date();
    compareDate.setHours(0, 0, 0, 0);
    return courseCostDetails.filter(course => {
      const startDate = new Date(course.startDate);
      const endDate = course.endDate ? new Date(course.endDate) : null;
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(0, 0, 0, 0);
      compareDate?.setHours(0, 0, 0, 0);
      if (endDate) {
        return compareDate >= startDate && compareDate <= endDate;
      } else {
        return compareDate >= startDate;
      }
    });
  }

  removeFromCompare(courseId: string): void {
    let compareIds = JSON.parse(localStorage.getItem('compareCourses') || '[]');
    compareIds = compareIds.filter((id: string) => id !== courseId);
    localStorage.setItem('compareCourses', JSON.stringify(compareIds));
    this.servicesService.emitEvent('remove');
  }

  navigateToCourses(): void {
    this.router.navigate(['/home/list-courses']);
  }

  viewCourse(courseId: string): void {
    this.router.navigate(['/home/view-course'], { queryParams: { courseId } });
  }

  addToWallet(method, courseId) {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
    } else {
      switch (method) {
        case 'add':
          this.favouritesService.addToFavourites(courseId, this.collectionName).subscribe(res => {
            this.snackbar.successToast(res?.message?.applicationMessage)
          }
          );
          break;
      }
    }
  }

  getFavouritesByCollectionName(collections) {    
    let collectionIds = collections?.map(obj => obj?.id);
    this.favouritesService.getFavouritesByCollectionName(this.collectionName, collectionIds).subscribe(res => {
        this.favouriteCoursesList = res?.body?.data?.userFavourites?.data || [];
    }, err => {
      this.snackbar.errorToast(err?.error?.applicationMessage)

    })
  }

  isCourseFavorite(courseId: string): boolean {
      return this.favouriteCoursesList?.some(favorite => favorite?.attributes?.targetId == courseId);
  }

  favouritesSection(method, courseId, listType) {
    if (!this.authService?.isLoggedIn) {
      this.router.navigate(['/home/sign-in']);
      return;
    }
    switch (method) {
      case 'add':
        this.favouritesService.addToFavourites(courseId, this.collectionName).subscribe(res => {
          this.snackbar.successToast(res?.message?.applicationMessage);
          this.getFavouritesByCollectionName(this.compareList);
        }, err => {
          this.snackbar.errorToast(err?.error?.applicationMessage);
        });
        break;

      case 'remove':
        let id;
          id = this.favouriteCoursesList?.find(course => course?.attributes?.targetId == courseId)?.id;
        this.favouritesService.removeFromFavourites(id).subscribe(res => {
          this.snackbar.successToast(res?.message?.applicationMessage);
          this.getFavouritesByCollectionName(this.compareList);
        }, err => {
          this.snackbar.errorToast(err?.error?.applicationMessage)
        });
        break;
    }
  }
  
  removeFromCompare1(courseId: string): void {
    let compareList = JSON.parse(localStorage.getItem('compareCourses') || '[]');
    compareList = compareList.filter((id: string) => id !== courseId);
    localStorage.setItem('compareCourses', JSON.stringify(compareList));
    this.loadCompareList();
  }
}
