import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import { Location } from '@angular/common';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';
import { SrmService } from '../srm.service';
import { TabsService } from '../../../shared/components/tabs/tabs.service';
import { FeatureConstants } from 'src/app/shared/constants/features-constants';


export interface MessageFormValues {
  messageText: any;
  messageToId: number;
  conersationWith: string;
}


@Component({
  selector: 'vc-create-srm',
  templateUrl: './create-srm.component.html',
  styleUrls: ['./create-srm.component.scss']
})
export class CreateSrmComponent implements OnInit {
  userList: any;
  messageForm: UntypedFormGroup;
  selectedKw = '';
  kwFilteredOptions: any;
  FILTER_CONFIG_SRM = 1;
  userId: any;
  @Output() messageSent = new EventEmitter();
  @Output() cancelMsg = new EventEmitter();

  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmService: ConfirmService,
    private readonly location: Location,
    private readonly srmService: SrmService,
    private readonly builder: UntypedFormBuilder,
    private readonly tabService: TabsService,

  ) {
    this.mainService.setPageTitle(FeatureConstants.Message, route.snapshot.data);
    this.userId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).userId;
  }

  ngOnInit() {
    this.tabService.emitChatFocus(true);

    this.srmService.getUserList(this.userId).subscribe(data=>{
      data.forEach((users: any) => {
        Object.assign(users, { userFullName: users.firstName + ' ' + users.lastName });
      });
      this.userList = data
    });
    this.initMessagesendForm();
    this.messageForm.get('keyWorker').valueChanges
      .subscribe( val => this.updateReference(val) );
  }

  updateReference(val) {
    const user = this.userList.find( obj => obj.id === +val);
    this.selectedKw = user ? user.userFullName : '';
  }

  cancelMessage() {
    this.cancelMsg.emit();
  }
  initMessagesendForm() {
    this.messageForm = this.builder.group({
      keyWorker: ['0', [Validators.required, this.validateKeyWorker]],
      message: ['', [Validators.required]]
    });
  }

  sendMessage() {
    const data = {
      status: 'SU',
      messageText: this.formatedText(this.messageForm.get('message').value),
      messageToId: this.messageForm.get('keyWorker').value
    };
    this.srmService.create(data).subscribe(() => {
      this.messageForm.reset();
      this.messageSent.emit();

    });
  }

  validateKeyWorker(control : UntypedFormControl) : {[key: string] : boolean} | null{
    if(control.value !== undefined && control.value === '0'){
      return { 'keyWorker': true };
    }
    return null;
  }

  ngOnDestroy(): void {
    this.tabService.emitChatFocus(false);
  }

  formatedText(message: any) {
    const urlPattern = /((https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}(\/\S*)?)/gi;
    return message.replace(urlPattern, (url) => {
      // Check if the URL starts with http or https, if not, prepend http
      const href = url.match(/^(https?:\/\/)/) ? url : `http://${url}`;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }
}
